<template>
  <main class="main" role="main">
    <div class="page bg banner_padding">
      <div class="inner">
        <div class="page_con" id="page_con">
          <div class="cate_tab bg">
            <div class="inner category_inner">
              <div class="btn_wrap market_tab">
                <button
                  class="all"
                  :class="{ on: index === currentIndex }"
                  v-for="(item, index) in marketCategories"
                  :key="index"
                  @click="fnMarket(index)"
                >
                  <img
                    :src="require(`@/assets/static/images/${item.enabledImg}`)"
                    v-if="!$Util.isEmpty(item.enabledImg)"
                    alt=""
                  />
                  <span v-else>{{ item.text }}</span>
                </button>
              </div>
              <div class="con_btn pd0" v-if="!$Util.isEmpty(userData)">
                <!-- <button class="btn bo">리스트 더보기</button> -->
                <button class="btn web_break" @click="fnMarketWrite">
                  마켓 등록하기</button
                ><!--버튼 중복: 웹 분기점 디자인 적용-->
              </div>
            </div>
          </div>
          <div
            class="party_list_wrap"
            v-on="
              pointer
                ? {
                    pointerdown: $event => start($event),
                    pointermove: $event => move($event),
                    pointerup: $event => end($event)
                  }
                : {
                    touchstart: $event => start($event),
                    touchmove: $event => move($event),
                    touchend: $event => end($event)
                  }
            "
          >
            <div class="list_top pc">
              <!-- mobile -->
              <div class="left">
                <div class="total">총 {{ totalCnt }}건</div>
              </div>
              <div class="right">
                <label class="checkbox" style="margin-right: 2rem;">
                  <input
                    type="checkbox"
                    :true-value="'Y'"
                    :false-value="'N'"
                    :checked="endYn === 'Y'"
                    v-model="endYn"
                  />
                  <span class="label"><span>종료 비노출</span></span>
                </label>
                <label class="select">
                  <select v-model="sort">
                    <option :value="'PRICE_ASC'">판매가순</option>
                    <option :value="'DC_DESC'">할인순</option>
                    <option :value="'REG_DESC'">신규등록순</option>
                    <option :value="'MOD_DESC'">업데이트순</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="list_top mb">
              <!-- mobile -->
              <div class="left">
                <div class="total">총 {{ totalCnt }}건</div>
              </div>
              <div class="right">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    :true-value="'Y'"
                    :false-value="'N'"
                    :checked="endYn === 'Y'"
                    v-model="endYn"
                  />
                  <span class="label"><span>종료 비노출</span></span>
                </label>
                <label class="select">
                  <select v-model="sort">
                    <option :value="'PRICE_ASC'">판매가순</option>
                    <option :value="'DC_DESC'">할인순</option>
                    <option :value="'REG_DESC'">신규등록순</option>
                    <option :value="'MOD_DESC'">업데이트순</option>
                  </select>
                </label>
              </div>
            </div>
            <market-list v-if="!$Util.isEmpty(list)" :items="list" />
            <div class="empty_wrap" v-else>
              <div class="empty">
                <div>상품 검색 결과가 없습니다.</div>
              </div>
            </div>

            <observe-visible
              :key="initCnt"
              :page="page"
              :limit="limit"
              :totalCnt="totalCnt"
              @fetch="fnListFunction"
            />
            <div class="con_btn" v-if="!$Util.isEmpty(userData)">
              <!--              <button class="btn bo">리스트 더보기</button>-->
              <button class="btn web_break" @click="fnMarketWrite">
                마켓 등록하기</button
              ><!--버튼 중복: 웹 분기점 디자인 적용-->
            </div>
          </div>
        </div>
        <div class="page_btn" v-if="!$Util.isEmpty(userData)">
          <div class="btn_wrap">
            <button class="btn" @click="fnMarketWrite">
              마켓 등록하기</button
            ><!--버튼 중복: 웹 분기점 디자인 적용-->
          </div>
        </div>
      </div>
    </div>
    <nice
      :id="'formNice8'"
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallback"
    />
  </main>
</template>

<script>
import { componentState } from './index.js'
import MarketList from '@/components/views/market/list/index.vue'

export default {
  name: 'market-list-index',
  components: { MarketList },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
