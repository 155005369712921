import { computed, watch, reactive, toRefs, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { CommonFunction } from '@/common/CommonFunction'
import { niceForm } from '@/common/form/NiceForm'

const INIT_PAGE = 0
const INIT_LIMIT = 20
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    onDepth1Item: computed(() => getters['nav/getOnDepth1Item']),
    onDepth2Item: computed(() => getters['nav/getOnDepth2Item']),
    pointer: computed(() => (window.PointerEvent ? true : false)),
    activeTab: Number(route.query.activeTab),
    touch: {},
    items: [],
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    searchText: '',
    totalCnt: INIT_TOTAL_CNT,
    list: [],
    initCnt: 0,
    sort: route.query.sort || 'REG_DESC',
    endYn: route.query.endYn || 'N',
    currentIndex: Number(route.query.index) || 0,
    categoryId: route.query.categoryId || '',
    params: computed({
      get: () => {
        return {
          startNum: state.list.length + 1,
          endNum: (state.page + 1) * state.limit,
          searchText: state.searchText,
          categoryId: state.categoryId,
          // categoryId:
          //   state.onDepth2Item.categoryID ||
          //   state.onDepth1Item.categoryID ||
          //   '',
          sort: state.sort,
          endYn: state.endYn
        }
      }
    }),
    timer: undefined,
    categories: proxy.$_.keyBy(proxy.$ConstCode.CATEGORY2_VALUE, 'id'),
    marketCategories: proxy.$ConstCode.CATEGORY3_VALUE.filter(
      item => item.id !== '2003'
    ),
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`
  })
  const {
    fnMarketWrite,
    fnUpdateAuthType,
    switchTab,
    start,
    move,
    end,
    setTouchPos
  } = CommonFunction(state)

  const fnShowOn = async index => {
    await dispatch('nav/updateOnDepth2Item', {
      onDepth2Item: state.items[index]
    })
    state.items[index].isShow = true
    state.items.forEach((mItem, mIndex) => {
      if (index !== mIndex) {
        mItem.isShow = false
      }
    })
    fnSearch()
  }
  const fnListFunction = async () => {
    const res = await proxy.$MarketSvc.postMarketList(state.params)
    if (res.resultCode === '0000') {
      state.page++
      state.totalCnt = res.totalCnt
      state.list = state.list.concat(res.list)
    } else if (res.resultCode === '0001') {
      // 데이터 없는경우
      fnReset()
    } else {
      alert(res.resultMsg)
    }
  }
  const fnSearch = () => {
    fnReset()
    state.initCnt++
  }
  const fnReset = () => {
    state.page = INIT_PAGE
    state.limit = INIT_LIMIT
    state.totalCnt = INIT_TOTAL_CNT
    state.list = []
  }

  const fnMarket = val => {
    router.push({
      name: 'market-list-index',
      query: {
        ...route.query,
        index: val,
        categoryId: state.marketCategories[val].id
      }
    })
  }

  const fnMore = () => {}

  const fnCallback = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      await router.push({ path: '/my-info/phone-auth' })
      // await fnMyInfo()
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }

  /** watch **/
  watch(
    () => state.onDepth1Item,
    () => {
      const all = {
        categoryID: '',
        categoryName: 'ALL',
        categoryOrder: '-1',
        depth: 2,
        upperCategoryID: '0000',
        url: ''
      }
      if (!proxy.$Util.isEmpty(state.onDepth1Item.categoryList)) {
        state.items = [all]
      } else {
        state.items = []
      }
      if (
        !proxy.$Util.isEmpty(state.onDepth1Item) &&
        !proxy.$Util.isEmpty(state.onDepth1Item.categoryList)
      ) {
        state.items = state.items.concat([...state.onDepth1Item.categoryList])
      }
      fnSearch()
    },
    { immediate: true }
  )
  watch(
    () => state.sort,
    () => {
      const r = proxy.$Util.getRandomString()
      router.push({
        name: 'market-list-index',
        query: { ...route.query, sort: state.sort, r }
      })
    }
  )
  watch(
    () => state.endYn,
    () => {
      const r = proxy.$Util.getRandomString()
      router.push({
        name: 'market-list-index',
        query: { ...route.query, endYn: state.endYn, r }
      })
    }
  )
  return {
    ...toRefs(state),
    fnShowOn,
    fnListFunction,
    fnSearch,
    fnMarketWrite,
    fnReset,
    fnCallback,
    fnMarket,
    switchTab,
    start,
    move,
    end,
    fnMore,
    setTouchPos
  }
}
